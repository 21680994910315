import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../environments/environment';
import {User} from '../_models';

@Injectable({providedIn: 'root'})
export class AccountService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  private logoutTimer: any;
  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.user = this.userSubject.asObservable();
  }

  setLogoutTimer() {
    this.logoutTimer = setTimeout(() => {
      this.logout();
    }, 900000); // 15 minutes in milliseconds
  }

  resetLogoutTimer() {
    clearTimeout(this.logoutTimer);
    this.setLogoutTimer();
  }



  public get userValue(): User {
    return this.userSubject.value;
  }

  login(username, password,ipAddress,browser) {
    // tslint:disable-next-line:variable-name
    const grant_type = 'password';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + btoa('6a4887ee0a9f3d2d6decedeb0ca5152581aee480:f49678ff0c6e27cb1a2b51f12afb6ea19b39dc51')
      })
    };


    return this.http.post<User>(`${environment.apiUrl}/login`, {username, password,grant_type, ipAddress,browser}, httpOptions)
      .pipe(map(user => {
        this.userSubject.next(user);
        return user;
      }));
  }

  pyLogin(username, password) {
    // tslint:disable-next-line:variable-name
    const grant_type = 'password';
    const body={ username, password, grant_type };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + btoa('6vdUmxZjnV8IiwHxbqsIxwpgB3JJL0HRfW8i6hS5:qfuIn8gIE1cAY0c9TDWuplx9s0Mh4pPKc80xBARk11eD8Am2ZfSp9dTzTQ1TIb5Y6vPWIpuiDaqsw1wexdAhxRtNlv4iD2sqAGcOeko0ozAh1JKnnp3X2REEMfN85XJG')
      })
    };
    // return this.http.post<User>(`${environment.pyUrl}/o/token/`, body, httpOptions);
  }

  logout() {
    // remove user from local storage and set current user to null
    const is_angiowave = JSON.parse(localStorage.getItem('angiowave'));
    localStorage.removeItem('user');
    localStorage.clear();
    this.userSubject.next(null);
    if(is_angiowave=='angiowave'){
      this.router.navigate(['/angiowave/login']);
    }else {
      this.router.navigate(['/account/login']);
    }
  }

  register(user: User) {
    return this.http.post(`${environment.apiUrl}/users/register`, user);
  }
  resetPassword(data) {
    const uId = JSON.parse(localStorage.getItem('uId'));
    data.id = uId;
    return this.http.post(`${environment.apiUrl}/changePassword`, data);
  }

  getAll() {
    return this.http.get<User[]>(`${environment.apiUrl}/patients`);
  }

  getById(id: string) {
    return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
  }

  verify(otp: string, id: string,ipAddress,browser) {
    // return this.http.get<User>(`${environment.apiUrl}/verify/${otp}/${id}`);
    return this.http.post<User>(`${environment.apiUrl}/verify`, {otp, id ,ipAddress,browser});
  }
  forgottenPassword(username) {
    // return this.http.get<User>(`${environment.apiUrl}/verify/${otp}/${id}`);
    return this.http.post(`${environment.apiUrl}/forgotPassword`, {username});
  }


  update(id, params) {
    return this.http.put(`${environment.apiUrl}/users/${id}`, params)
      .pipe(map(x => {
        // update stored user if the logged in user updated their own record
        if (id == this.userValue.id) {
          // update local storage
          const user = {...this.userValue, ...params};
          localStorage.setItem('user', JSON.stringify(user));

          // publish updated user to subscribers
          this.userSubject.next(user);
        }
        return x;
      }));
  }

  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/users/${id}`)
      .pipe(map(x => {
        // auto logout if the logged in user deleted their own record
        if (id == this.userValue.id) {
          this.logout();
        }
        return x;
      }));
  }
}
