<div class="container-fluid">
  <app-pagetitle title="Responses" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <h4 class="card-title"><strong>Study: </strong>{{studyName}}</h4>
            </div>
            <div class="col-md-6">
              <a routerLink="/" class="btn btn-info" style="float: right"><i class="fa fa-arrow-left"></i> Back</a>

              <br><br>
            </div>
          </div>
          <div class="table table-bordered table-striped table-responsive">
            <table class="table mb-0">

              <thead>
              <tr>
                <th>#</th>
                <th>Dashboard Name</th>
                <th>Action</th>

              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of questions">
                <td>{{data.id}}</td>
                <td>{{data.name}}</td>
                <td><a routerLink="/dashboard-render/{{data.id}}" class="btn btn-sm btn-primary">View</a></td>

              </tr>
              <tr *ngIf="!questions">
                <td colspan="4" class="text-center">
                  <span class="spinner-border spinner-border-lg align-center"></span>
                </td>
              </tr>

              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</div>
