import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';

import {HttpClient} from '@angular/common/http';
import {Questions} from "../questions/questions";
import {Observable} from "rxjs";
import { Table, SearchResult } from './reply/studies/advancedtable/advanced.model';

@Injectable({
  providedIn: 'root'
})
export class ResponseService {

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
  }


  create(data) {
    const user = JSON.parse(localStorage.getItem('user'));
    data.created_by = user.u;
    return this.http.post(`${environment.apiUrl}/response`, data);
  }

  getSubmittedData(sid, siteId, studyFormId) {
    return this.http.get<Questions>(`${environment.apiUrl}/getsubmitteddata/${sid}/${siteId}/${studyFormId}`);
  }


  getDashboardData(sid, siteId, gid) {
      return this.http.get(`${environment.apiUrl}/getGridDashboard/${sid}/${siteId}/${gid}`);
  }

  getSubmittedDatawithpid(sid, siteId, studyFormId, pid) {
    return this.http.get<Questions>(`${environment.apiUrl}/getsubmitteddata/${sid}/${siteId}/${studyFormId}/${pid}`);
  }

  getAllwithpid(sid, siteId, studyFormId, pid) {
    return this.http.get<Questions>(`${environment.apiUrl}/getresponse/${sid}/${siteId}/${studyFormId}/${pid}`);
  }

  verify(sid, pid) {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http.get<Questions>(`${environment.apiUrl}/verifyPatientResponses/${sid}/${pid}/${user.u}`);
  }


  getpatientbyid1(sid, siteId) {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http.get<Questions>(`${environment.apiUrl}/getpatientbyid1/${sid}/${siteId}/${user.u}`);
  }

  getpatientbyid(sid, siteId) {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http.get<Questions>(`${environment.apiUrl}/getpatientbyid/${sid}/${siteId}/${user.u}`);
  }


  getTables(sid, siteId, page: number, pageSize: number, searchTerm?: string): Observable<SearchResult> {
    const user = JSON.parse(localStorage.getItem('user'));
    let url = `${environment.apiUrl}/getpatientbyid/${sid}/${siteId}/${user.u}?page=${page}&pageSize=${pageSize}`;
    if (searchTerm && searchTerm.length > 4) {
      url += `&searchTerm=${encodeURIComponent(searchTerm)}`;
    }
    return this.http.get<SearchResult>(url);
  }


  getactivestudies() {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http.get(`${environment.apiUrl}/getactivestudies/${user.u}`);
  }

  getSitebyStudy(sid) {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http.get(`${environment.apiUrl}/getSiteByStudy//${user.u}/${sid}`);
  }

  viewExcelFormat(sid, siteId, formId) {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http.get(`${environment.apiUrl}/downloadExcelFormat/${sid}/${formId}/${siteId}`);
  }

  uploadExcelData(data) {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http.post(`${environment.apiUrl}/uploadExcelData`, data);
  }

}
