import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IframeService {
  constructor(
    private router: Router,
    private http: HttpClient
  ) {
  }

  create(data) {
    const user = JSON.parse(localStorage.getItem('user'));
    data.created_by = user.u;
    return this.http.post(`${environment.apiUrl}/IframeQuestionController`, data);
  }

  codeExecute(data) {
    const user = JSON.parse(localStorage.getItem('user'));
    data.created_by = user.u;
    return this.http.post(`${environment.apiUrl}/codeExecute`, data);
  }

  getAll() {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http.get(`${environment.apiUrl}/IframeGetAllQuestions/${user.u}`);
  }
  
  getByStudyId(id) {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http.get(`${environment.apiUrl}/IframeGetAllQuestions/${user.u}/${id}`);
  }

  getById(id) {
    return this.http.get(`${environment.apiUrl}/IframeQuestionController/${id}`);
  }

  update(id, params) {
    return this.http.put(`${environment.apiUrl}/IframeQuestionController/${id}`, params)
      .pipe(map(x => {
        // update stored user if the logged in user updated their own record

        return x;
      }));
  }

  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/IframeQuestionController/${id}`);
  }


}
