export class User {
    get access_token(): string {
        return this._access_token;
    }

    set access_token(value: string) {
        this._access_token = value;
    }
    id: string;
    username: string;
    password: string;
    firstName: string;
    role: string;
    // lastName: string;
    // token: string;
  // tslint:disable-next-line:variable-name
    private _access_token: string;
}
