import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {AccountService} from '../_services';

@Injectable({providedIn: 'root'})
export class RoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private accountService: AccountService
  ) {
  }

  status: boolean = false;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // return true;
    // console.log(route)
    const url = state.url.replace(/[0-9]/g, '');

    this.status=false;
    const permissions = JSON.parse(localStorage.getItem('permissions'));
    if (permissions) {
      permissions.forEach(element => {
        if (url == element) {
          this.status = true;
        }
      });
      return this.status;
    }


  }
}
