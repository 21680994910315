<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title"></h4>
          <div class="row">
            <div class="col-md-6">
              <h4 class="card-title" >Study Dashboard ({{studyName}})</h4>
              <br><br>
            </div>
            <div class="col-md-6">
              <a routerLink="/dashboard-grid-list/{{sid}}" class="btn btn-info" style="float: right"><i class="fa fa-arrow-left"></i> Back</a>
              <br><br>
            </div>
              <div class="col-md-12" *ngIf="sites">
                <div class="form-group">
                  <label>Select Site <b class="text-danger">*</b></label>
                  <ng-select (change)="siteChange($event)">
                    <ng-option *ngFor="let opt of sites" [value]="opt.id">{{opt.site_name}}</ng-option>
                  </ng-select>
                  <div class="valid-feedback">
                    <span>Looks good!</span>
                  </div>

                </div>
              </div>

          </div>

          <div class="row">
            <div class="col-md-12" style="text-align: center" *ngIf="loader">
    <span style="width:7rem;height:7rem;text-align: center"
          class="spinner-border spinner-border-lg mr-1"></span>
            </div>
            <gridster *ngIf="!loader" [options]="options">
              <gridster-item [item]="item" *ngFor="let item of items; let i = index"  style="overflow-y: scroll">

                <div *ngIf="typeArray[i]=='Iframe'" class="col-12" style="width:100%;height:100%">
                  <br>
                  <div class="output-container" style="width:100%;height:100%">
                    <iframe #pythonOutputFrame  style="width:100%;height:100%" class="output-frame" [id]="'pythonOutputFrame_' + i"></iframe>
                  </div>
                  <br>
                </div>
                <div *ngIf="typeArray[i]=='Table'" class="col-12">

                  <div class="row" [innerHTML]="questionArray[i].table">
                  </div>
                  <br>
                </div>
                <div *ngIf="typeArray[i]=='Chart'" class="col-12" style="height: 100%;width:100%">
                  <div  class="col-12" style="height: 100%;width:100%"  *ngIf="formArray[i].chartField=='Line'">
                    <br>
                    <h5>{{questionArray[i].question}}</h5>
                    <!-- Line Chart -->
                    <canvas baseChart [datasets]="[
        {
            label: questionArray[i].question,
            fill: true,
            lineTension: 0.5,
            backgroundColor: 'rgba(85, 110, 230, 0.2)',
            borderColor: '#5664d2',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#5664d2',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#5664d2',
            pointHoverBorderColor: '#fff',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: questionArray[i].value
        }
    ]" [labels]="questionArray[i].label"
                            [options]="lineAreaChart.options" chartType="line">
                    </canvas>
                  </div>

                  <div  class="col-12" style="height: 100%;width:100%"  *ngIf="formArray[i].chartField=='Bar'">
                    <br>
                    <h5>{{questionArray[i].question}}</h5>
                    <canvas baseChart [datasets]=" [
        {
            label: questionArray[i].question,
            backgroundColor: 'rgba(52, 195, 143, 0.8)',
            borderColor: 'rgba(52, 195, 143, 0.8)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(52, 195, 143, 0.9)',
            hoverBorderColor: 'rgba(52, 195, 143, 0.9)',
            data: questionArray[i].value,
            barPercentage: 0.4

        }
    ]" chartType="bar" [options]="lineBarChart.options"
                            [labels]="questionArray[i].label">
                    </canvas>

                  </div>
                  <div  class="col-12" style="height: 100%;width:100%"  *ngIf="formArray[i].chartField=='Pie'">
                    <br>
                    <h5>{{questionArray[i].question}}</h5>
                    <canvas baseChart [datasets]="[
        {
            data: questionArray[i].value,
            backgroundColor: ['#1cbb8c', '#5664d2', '#fcb92c', '#4aa3ff', '#ff3d60','#1cbb8c', '#ebeff2', '#aaefd3', '#abefe2', '#ebafa2', '#cbefb2', '#cbcfc2'],
            hoverBackgroundColor: ['#1cbb8c', '#5664d2', '#fcb92c', '#4aa3ff', '#ff3d60','#1cbb8c', '#ebeff2', '#aaefd3', '#abefe2', '#ebafa2', '#cbefb2', '#cbcfc2'],
            hoverBorderColor: '#fff'
        }
    ]" chartType="pie" [options]="pieChart.options"
                            [labels]="questionArray[i].label">
                    </canvas>
                  </div>
                  <div  class="col-12" style="height: 100%;width:100%" *ngIf="formArray[i].chartField=='Trend'">
                    <br>
                    <h5>{{questionArray[i].question}}</h5>
                    <apx-chart class="apex-charts" dir="ltr" [series]="[{
    name: 'Bar',
    type: 'column',
    data: questionArray[i].value
  }, {
    name: 'Line',
    type: 'area',
    data: questionArray[i].value
  }]"
                               [chart]="lineColumAreaChart.chart" [tooltip]="lineColumAreaChart.tooltip"
                               [yaxis]="lineColumAreaChart.yaxis"
                               [legend]="lineColumAreaChart.legend" [plotOptions]="lineColumAreaChart.plotOptions"
                               [grid]="lineColumAreaChart.grid" [stroke]="lineColumAreaChart.stroke"
                               [xaxis]="lineColumAreaChart.xaxis"
                               [colors]="lineColumAreaChart.colors" [fill]="lineColumAreaChart.fill" [labels]="questionArray[i].label"
                               [markers]="lineColumAreaChart.markers">
                    </apx-chart>
                  </div>
                  <div  class="col-12" style="height: 100%;width:100%" *ngIf="formArray[i].chartField=='Scatter'">
                    <br>
                    <h5>{{questionArray[i].question}}</h5>
                    <div echarts [options]="questionArray[i].scatterData" class="demo-chart"></div>
                  </div>
                  <br><br>
                </div>

              </gridster-item>
            </gridster>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>
