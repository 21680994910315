import {ChartType} from './chartjs.model';

const lineAreaChart: ChartType = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October'],
  datasets:
    [
      {
        label: 'Sales Analytics',
        fill: true,
        lineTension: 0.5,
        backgroundColor: 'rgba(85, 110, 230, 0.2)',
        borderColor: '#5664d2',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#5664d2',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#5664d2',
        pointHoverBorderColor: '#fff',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [65, 59, 80, 81, 56, 55, 40, 55, 30, 80]
      },
      {
        label: 'Monthly Earnings',
        fill: true,
        lineTension: 0.5,
        backgroundColor: 'rgba(252, 185, 44, 0.2)',
        borderColor: '#fcb92c',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#fcb92c',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#fcb92c',
        pointHoverBorderColor: '#eef0f2',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [80, 23, 56, 65, 23, 35, 85, 25, 92, 36]
      }
    ],
  options:
    {
      defaultFontColor: '#8791af',
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            ticks: {
              display: false
            }
          }
        ]
      },

    }
};

const lineBarChart: ChartType = {
  labels: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August'
  ],
  datasets:
    [
      {
        label: 'Analytics',
        backgroundColor: 'rgba(52, 195, 143, 0.8)',
        borderColor: 'rgba(52, 195, 143, 0.8)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(52, 195, 143, 0.9)',
        hoverBorderColor: 'rgba(52, 195, 143, 0.9)',
        data: [65, 59, 81, 45, 56, 80, 50, 20, ''],
        barPercentage: 0.4

      },
    ],
  options:
    {
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            ticks: {
              display: false
            }
          }
        ]
      },
    }
};

const pieChart: ChartType = {
  labels: [
    'Desktops', 'Tablets'
  ],
  datasets: [
    {
      data: [300, 180, 100, 180, 100, 180, 100, 180, 100, 180, 100, 180, 100],
      backgroundColor: ['#1cbb8c', '#ebeff2', '#aaefd3', '#abefe2', '#ebafa2', '#cbefb2', '#cbcfc2'],
      hoverBackgroundColor: ['#1cbb8c', '#ebeff2', '#aaefd3', '#abefe2', '#ebafa2', '#cbefb2', '#cbcfc2'],
      hoverBorderColor: '#fff',
    }
  ],
  options: {
    maintainAspectRatio: false,
    legend: {
      position: 'top',
    }
  }
};

const donutChart: ChartType = {
  labels: [
    'Desktops', 'Tablets'
  ],
  datasets: [
    {
      data: [300, 210],
      backgroundColor: [
        '#556ee6', '#ebeff2'
      ],
      hoverBackgroundColor: ['#556ee6', '#ebeff2'],
      hoverBorderColor: '#fff',
    }],
  options: {
    maintainAspectRatio: false,
    legend: {
      position: 'top',
    }
  }
};

const radarChart: ChartType = {
  labels: [
    'Eating',
    'Drinking',
    'Sleeping',
    'Designing',
    'Coding',
    'Cycling',
    'Running',
  ],
  datasets: [
    {
      label: 'Desktops',
      backgroundColor: 'rgba(252, 185, 44, 0.2)',
      borderColor: '#fcb92c',
      pointBackgroundColor: '#fcb92c',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#fcb92c',
      data: [65, 59, 90, 81, 56, 55, 40],
    },
    {
      label: 'Tablets',
      backgroundColor: 'rgba(84, 56, 220, 0.2)',
      borderColor: '#5664d2',
      pointBackgroundColor: '#5664d2',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#5664d2',
      data: [28, 48, 40, 19, 96, 27, 100],
    },
  ],
  options: {
    maintainAspectRatio: false,
    legend: {
      position: 'top'
    }
  }
};

const polarChart: ChartType = {
  labels: ['Series 1', 'Series 2', 'Series 3', 'Series 4'],
  datasets: [
    {
      data: [11, 16, 7, 18],
      backgroundColor: ['#fcb92c', '#1cbb8c', '#ff3d60', '#5664d2'],
      label: 'My dataset', // for legend
      hoverBorderColor: '#fff',
    },
  ],
  options: {
    responsive: true,
    legend: {
      position: 'top',
    }
  }
};
const lineColumAreaChart: ChartType = {

  chart: {
    height: 350,
    type: 'line',
    stacked: false,
    toolbar: {
      show: false
    }
  },
  stroke: {
    width: [0, 2, 4],
    curve: 'smooth'
  },

  plotOptions: {
    bar: {
      columnWidth: '50%'
    }
  },
  colors: ['#1cbb8c', '#fcb92c', '#5664d2'],
  series: [{
    name: 'Bar',
    type: 'column',
    data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
  }, {
    name: 'Line',
    type: 'area',
    data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
  }],
  fill: {
    opacity: [0.85, 0.25, 1],
    gradient: {
      inverseColors: false,
      shade: 'light',
      type: 'vertical',
      opacityFrom: 0.85,
      opacityTo: 0.55,
      stops: [0, 100, 100, 100]
    }
  },
  // tslint:disable-next-line: max-line-length
  labels: ['10', '11'],
  markers: {
    size: 0
  },
  legend: {
    offsetY: -10,
  },
  xaxis: {
    labels: {
      show: false,
    }
  },
  yaxis: {
    title: {
      text: 'Points',
    },
  },

  tooltip: {
    shared: true,
    intersect: false,
    y: {
      formatter(y) {
        if (typeof y !== 'undefined') {
          return y.toFixed(0) + ' points';
        }
        return y;
      }
    }
  },
  grid: {
    borderColor: '#f1f1f1'
  }
};
var data = [
  [[28604, 77, 17096869, 'Australia', 1990], [31163, 77.4, 27662440, 'Canada', 1990], [1516, 68, 1154605773, 'China', 1990], [13670, 74.7, 10582082, 'Cuba', 1990], [28599, 75, 4986705, 'Finland', 1990], [29476, 77.1, 56943299, 'France', 1990], [31476, 75.4, 78958237, 'Germany', 1990], [28666, 78.1, 254830, 'Iceland', 1990], [1777, 57.7, 870601776, 'India', 1990], [29550, 79.1, 122249285, 'Japan', 1990], [2076, 67.9, 20194354, 'North Korea', 1990], [12087, 72, 42972254, 'South Korea', 1990], [24021, 75.4, 3397534, 'New Zealand', 1990], [43296, 76.8, 4240375, 'Norway', 1990], [10088, 70.8, 38195258, 'Poland', 1990], [19349, 69.6, 147568552, 'Russia', 1990], [10670, 67.3, 53994605, 'Turkey', 1990], [26424, 75.7, 57110117, 'United Kingdom', 1990], [37062, 75.4, 252847810, 'United States', 1990]],
  [[44056, 81.8, 23968973, 'Australia', 2015], [43294, 81.7, 35939927, 'Canada', 2015], [13334, 76.9, 1376048943, 'China', 2015], [21291, 78.5, 11389562, 'Cuba', 2015], [38923, 80.8, 5503457, 'Finland', 2015], [37599, 81.9, 64395345, 'France', 2015], [44053, 81.1, 80688545, 'Germany', 2015], [42182, 82.8, 329425, 'Iceland', 2015], [5903, 66.8, 1311050527, 'India', 2015], [36162, 83.5, 126573481, 'Japan', 2015], [1390, 71.4, 25155317, 'North Korea', 2015], [34644, 80.7, 50293439, 'South Korea', 2015], [34186, 80.6, 4528526, 'New Zealand', 2015], [64304, 81.6, 5210967, 'Norway', 2015], [24787, 77.3, 38611794, 'Poland', 2015], [23038, 73.13, 143456918, 'Russia', 2015], [19360, 76.5, 78665830, 'Turkey', 2015], [38225, 81.4, 64715810, 'United Kingdom', 2015], [53354, 79.1, 321773631, 'United States', 2015]]
];
const bubbleChart: ChartType =
  {
    grid: {
      zlevel: 0,
      x: 50,
      x2: 50,
      y: 30,
      y2: 30,
      borderWidth: 0,
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: 'rgba(0,0,0,0)',
    },
    legend: {
      right: 10,
      data: ['2018', '2019']
    },
    xAxis: {
      axisLine: {
        lineStyle: {
          color: '#8791af'
        },
      },
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: "rgba(166, 176, 207, 0.1)"
        }
      },
    },
    yAxis: {
      axisLine: {
        lineStyle: {
          color: '#8791af'
        },
      },
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: "rgba(166, 176, 207, 0.1)"
        }
      },
      scale: true
    },
    series: [{
      name: '2018',
      data: data[0],
      type: 'scatter',
      symbolSize: function (data) {
        return Math.sqrt(data[2]) / 5e2;
      },
      label: {
        emphasis: {
          show: true,
          formatter: function (param) {
            return param.data[3];
          },
          position: 'top'
        }
      },
      itemStyle: {
        normal: {
          shadowBlur: 10,
          shadowColor: 'rgba(85, 110, 230, 0.5)',
          shadowOffsetY: 5,
          color: '#556ee6'
        }
      }
    }, {
      name: '2019',
      data: data[1],
      type: 'scatter',
      symbolSize: function (data) {
        return Math.sqrt(data[2]) / 5e2;
      },
      label: {
        emphasis: {
          show: true,
          formatter: function (param) {
            return param.data[3];
          },
          position: 'top'
        }
      },
      itemStyle: {
        normal: {
          shadowBlur: 10,
          shadowColor: 'rgba(52, 195, 143, 0.5)',
          shadowOffsetY: 5,
          color: '#f46a6a'
        }
      }
    }],
    tooltip: {
      trigger: 'axis'
    },
  }

export {lineAreaChart, lineBarChart, pieChart, donutChart, radarChart, polarChart, lineColumAreaChart, bubbleChart};
