<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box" *ngIf="is_angiowave!='angiowave'">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo-sm-dark.png" alt="" height="25">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="50">
          </span>
        </a>

        <a href="/" class="logo logo-light">
          <span class="logo-sm">
<!--            <h3 style="color:#fff">S C</h3>-->
            <img src="assets/images/logo-sm.png" alt="" height="25">
          </span>
          <span class="logo-lg">
<!--            <h5 style="color:#fff;padding-top: 20px"><b>SEMPER CENTRUM</b></h5>-->
            <img src="assets/images/logo-light.png" alt="" height="50">
          </span>
        </a>
      </div>

      <div class="navbar-brand-box" *ngIf="is_angiowave=='angiowave'">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/angiowave-logo1.png" alt="" height="25">
          </span>
          <span class="logo-lg">
            <img src="assets/images/angiowave-logo1.png" alt="" height="50">
          </span>
        </a>

        <a href="/" class="logo logo-light">
          <span class="logo-sm">
<!--            <h3 style="color:#fff">S C</h3>-->
            <img src="assets/images/angiowave-logo1.png" alt="" height="25">
          </span>
          <span class="logo-lg">
<!--            <h5 style="color:#fff;padding-top: 20px"><b>SEMPER CENTRUM</b></h5>-->
            <img src="assets/images/angiowave-logo1.png" alt="" height="50">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn"
              (click)="toggleMobileMenu($event)">
        <i class="ri-menu-2-line align-middle"></i>
      </button>

      <!-- App Search-->
<!--            <form class="app-search d-none d-lg-block">-->
<!--              <div class="position-relative">-->
<!--                <input type="text" class="form-control" (keyup)="studiesFilter($event.target.value)" placeholder="{{'HEADER.SEARCH' | translate}}">-->
<!--                <span class="ri-search-line"></span>-->
<!--              </div>-->
<!--            </form>-->


    </div>

    <div class="d-flex">


      <div class="dropdown d-inline-block user-dropdown" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item waves-effect" id="page-header-user-dropdown"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img class="rounded-circle header-profile-user" src="assets/images/users/avatar.png" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ml-1">{{this.username}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>

        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <!-- item-->
          <a _ngcontent-wfm-c189="" routerLink="/logs" class="dropdown-item" *ngIf="logs">
            <i _ngcontent-wfm-c189="" class="ri-history-line align-middle mr-1"></i>
            Logs</a>
          <a _ngcontent-wfm-c189="" routerLink="/users/change-password" class="dropdown-item">
            <i _ngcontent-wfm-c189="" class="ri-key-line align-middle mr-1"></i>
            Change Password</a>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
            class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>
      </div>


    </div>
  </div>
</header>
