import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
// @ts-ignore
import { Table, SearchResult } from 'advancedtable/advanced.model';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {
  constructor(
    private router: Router,
    private http: HttpClient
  ) {
  }

  create(data) {
    const user = JSON.parse(localStorage.getItem('user'));
    data.created_by = user.u;
    return this.http.post(`${environment.apiUrl}/templateQuestions`, data);
  }



  getAll() {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http.get(`${environment.apiUrl}/getAllQueryQuestionByUser/${user.u}`);
  }

  getTables(page: number, pageSize: number): Observable<SearchResult> {
    const user = JSON.parse(localStorage.getItem('user'));
    const url = `${environment.apiUrl}/getAllQueryQuestionByUser/${user.u}?page=${page}&pageSize=${pageSize}`;
    return this.http.get<SearchResult>(url);
  }

  getAllQuestionsByStudy(sid) {
    return this.http.get(`${environment.apiUrl}/getAllQuestionsByStudy/${sid}`);
  }

  getQuestionFields(sid) {
    return this.http.get(`${environment.apiUrl}/getQuestionFields/${sid}`);
  }

  getQuestionCategoryValues(qid) {
    return this.http.get(`${environment.apiUrl}/getQuestionCategoryValues/${qid}`);
  }


  getById(id: string) {
    return this.http.get(`${environment.apiUrl}/templateQuestions/${id}`);
  }

  update(id, params) {
    return this.http.put(`${environment.apiUrl}/templateQuestions/${id}`, params)
      .pipe(map(x => {
        // update stored user if the logged in user updated their own record

        return x;
      }));
  }

  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/templateQuestions/${id}`);
  }

  whatsappExportGetData(data){
    return this.http.post(`${environment.apiUrl}/whatsappExportData`,data);
  }
}
