import { Component,HostListener  } from '@angular/core';
import {AccountService} from "./_services";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Semper Centrum';

  constructor(private accountService: AccountService) {}

  @HostListener('document:mousemove')
  @HostListener('document:keypress')
  @HostListener('document:click')
  onUserActivity() {
    this.accountService.resetLogoutTimer();
  }


}
