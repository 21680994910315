import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Organizations } from './organizations';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {


  private organizationSubject: BehaviorSubject<Organizations>;
  private organization: Observable<Organizations>;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.organizationSubject = new BehaviorSubject<Organizations>(JSON.parse(localStorage.getItem('user')));
    this.organization = this.organizationSubject.asObservable();
  }
  public get organizationValue(): Organizations {
    return this.organizationSubject.value;
  }
  create(data: Organizations) {
    const user = JSON.parse(localStorage.getItem('user'));
    data.created_by = user.u;
    return this.http.post(`${environment.apiUrl}/organizations`, data);
  }

  getAll() {
    return this.http.get<Organizations[]>(`${environment.apiUrl}/organizations`);
  }

  getById(id: string) {
    return this.http.get<Organizations>(`${environment.apiUrl}/organizations/${id}`);
  }
  update(id, params) {
    const user = JSON.parse(localStorage.getItem('user'));
    params.updated_by = user.u;
    return this.http.put(`${environment.apiUrl}/organizations/${id}`, params)
      .pipe(map(x => {
        // update stored user if the logged in user updated their own record

        return x;
      }));
  }

}
