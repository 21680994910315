import { Component, OnInit } from '@angular/core';
import { StudyGridService } from '../../../grid/studyGrid.service';
import { StudiesService } from '../../../studies/studies.service';
import {first} from 'rxjs/operators';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {
  // bread crumb items
  breadCrumbItems: Array<{}>;
  questions: any;
  public study: any;
  public patient: any;
  public sid: any;
  studyName: any;

  constructor(
    private studyGridService: StudyGridService,
    private studyService: StudiesService,
    private route: ActivatedRoute) {}

  ngOnInit() {

    this.sid = this.route.snapshot.params.id;
    localStorage.setItem('render_study_id', JSON.stringify(this.sid));
    this.breadCrumbItems = [{ label: 'Dashboard' }, { label: 'Grid List', active: true }];
    this.studyService.getById(this.sid)
      .subscribe(data => {
        // @ts-ignore
        this.studyName = data.name;
        // @ts-ignore
        localStorage.setItem('render_study_name', JSON.stringify(data.name));
      });
    this.studyGridService.getAll(this.sid)
        .subscribe(data => {
          // @ts-ignore
          this.questions = data;
        });
  }
}
