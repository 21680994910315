import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_helpers';
import { RoleGuard } from './_helpers/role.guard';

import { LayoutComponent } from './layouts/layout/layout.component';
import {ChangepasswordGuard} from './_helpers/changepassword.guard';
const accountModule = () => import('./auth/account.module').then(x => x.AccountModule);
const aModule = () => import('./angiowave/account.module').then(x => x.AccountModule);

const routes: Routes = [
  { path: 'account', loadChildren: accountModule },
  { path: 'angiowave', loadChildren: aModule },
  // tslint:disable-next-line:max-line-length
  { path: '', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [ AuthGuard, ChangepasswordGuard]  },
  { path: 'users', component: LayoutComponent, loadChildren: () => import('./users/users.module').then(m => m.UsersModule), canActivate: [AuthGuard]   },
  { path: 'organizations', component: LayoutComponent, loadChildren: () => import('./organizations/organizations.module').then(m => m.OrganizationsModule), canActivate: [AuthGuard, RoleGuard, ChangepasswordGuard]  },
  { path: 'studies', component: LayoutComponent, loadChildren: () => import('./studies/studies.module').then(m => m.StudiesModule) },
  { path: 'questions', component: LayoutComponent, loadChildren: () => import('./questions/questions.module').then(m => m.QuestionsModule), canActivate: [AuthGuard, RoleGuard, ChangepasswordGuard]  },
  { path: 'flags', component: LayoutComponent, loadChildren: () => import('./flags/flags.module').then(m => m.FlagsModule), canActivate: [AuthGuard, RoleGuard, ChangepasswordGuard]  },
  { path: 'response', component: LayoutComponent, loadChildren: () => import('./response/response.module').then(m => m.ResponseModule), canActivate: [AuthGuard, RoleGuard, ChangepasswordGuard]  },
  { path: 'forms', component: LayoutComponent, loadChildren: () => import('./forms/forms.module').then(m => m.FormsModule), canActivate: [AuthGuard, RoleGuard, ChangepasswordGuard]  },

  { path: 'medications', component: LayoutComponent, loadChildren: () => import('./medications/medications.module').then(m => m.MedicationsModule), canActivate: [AuthGuard, RoleGuard, ChangepasswordGuard]  },
  { path: 'occupations', component: LayoutComponent, loadChildren: () => import('./occupations/occupations.module').then(m => m.OccupationsModule), canActivate: [AuthGuard, RoleGuard, ChangepasswordGuard]  },
  { path: 'educations', component: LayoutComponent, loadChildren: () => import('./educations/educations.module').then(m => m.EducationsModule), canActivate: [AuthGuard, RoleGuard, ChangepasswordGuard]  },
  { path: 'health-insurances', component: LayoutComponent, loadChildren: () => import('./health/health.module').then(m => m.HealthModule), canActivate: [AuthGuard, RoleGuard, ChangepasswordGuard]  },

  { path: 'reporting', component: LayoutComponent, loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingModule), canActivate: [AuthGuard, RoleGuard, ChangepasswordGuard]  },
  { path: 'roles', component: LayoutComponent, loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule), canActivate: [AuthGuard, RoleGuard, ChangepasswordGuard]  },
  { path: 'sites', component: LayoutComponent, loadChildren: () => import('./sites/sites.module').then(m => m.SitesModule), canActivate: [AuthGuard, ChangepasswordGuard]  },

  { path: 'analysis', component: LayoutComponent, loadChildren: () => import('./analysis/analysis.module').then(m => m.AnalysisModule), canActivate: [AuthGuard, RoleGuard, ChangepasswordGuard]  },
  { path: 'chart', component: LayoutComponent, loadChildren: () => import('./pages/chart/chart.module').then(m => m.ChartModule) },

  { path: 'logs', component: LayoutComponent, loadChildren: () => import('./logs/logs.module').then(m => m.LogsModule), canActivate: [AuthGuard, RoleGuard, ChangepasswordGuard]  },
  { path: 'settings', component: LayoutComponent, loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule), canActivate: [AuthGuard, ChangepasswordGuard]  },
  { path: 'query/question', component: LayoutComponent, loadChildren: () => import('./grid/questions/questions.module').then(m => m.QuestionsModule)  },
  { path: 'iframe/builder', component: LayoutComponent, loadChildren: () => import('./grid/iframe/iframe.module').then(m => m.IframeModule)  },
  {path:'whatsapp',component:LayoutComponent,loadChildren: () => import('./whatsapp/whatsapp.module').then(m => m.WhatsappModule), canActivate: [AuthGuard, ChangepasswordGuard]}
  // otherwise redirect to home
  // { path: '**', redirectTo: '' }

];






@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
