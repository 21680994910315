import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {AccountService} from '../_services';

@Injectable({providedIn: 'root'})
export class ChangepasswordGuard implements CanActivate {
  constructor(
    private router: Router,
    private accountService: AccountService
  ) {
  }

  status: boolean = false;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return true;
    // console.log(route)

    const user = JSON.parse(localStorage.getItem('user'));
    const password_updated_at = user.password_updated_at;
    if (password_updated_at) {
      return true;
    } else {
      const returnUrl = '/users/change-password';
      this.router.navigate([returnUrl]);
      return false;
    }



  }
}
