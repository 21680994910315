import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Studies } from './studies';
import {Questions} from "../questions/questions";

@Injectable({
  providedIn: 'root'
})
export class StudiesService {


  private studiesSubject: BehaviorSubject<Studies>;
  private studies: Observable<Studies>;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.studiesSubject = new BehaviorSubject<Studies>(JSON.parse(localStorage.getItem('user')));
    this.studies = this.studiesSubject.asObservable();
  }
  public get studiesValue(): Studies {
    return this.studiesSubject.value;
  }
  create(data: Studies) {
    const user = JSON.parse(localStorage.getItem('user'));
    data.created_by = user.u;
    return this.http.post(`${environment.apiUrl}/studies`, data);
  }



  getAllForm(study_id) {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http.get<Studies[]>(`${environment.apiUrl}/getAllStudyForms/${study_id}`);
  }

  getAllLogs(study_id) {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http.get<Studies[]>(`${environment.apiUrl}/getAllLogs/${study_id}`);
  }

  // tslint:disable-next-line:variable-name
  getQueryQuestions(study_id) {
    return this.http.get<Studies[]>(`${environment.apiUrl}/getAllQuestionsByStudy/${study_id}`);
  }
  getStudyDataInArray(study_id, site_id, q_id) {
    return this.http.get<Studies[]>(`${environment.apiUrl}/getStudyDataInArray/${study_id}/${site_id}/${q_id}`);
  }

  getAll() {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http.get<Studies[]>(`${environment.apiUrl}/getallstudies/${user.u}`);
  }

  getAllGroups() {
    return this.http.get<Studies[]>(`${environment.apiUrl}/getStudiesGroups`);
  }

  getById(id: string) {
    return this.http.get<Studies>(`${environment.apiUrl}/studies/${id}`);
  }

  getStudiesUsers(id: string) {
    return this.http.get<Studies>(`${environment.apiUrl}/getStudiesUsers/${id}`);
  }

  editstudy(id: string) {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http.get<Studies>(`${environment.apiUrl}/editstudy/${id}/${user.u}`);
  }
  update(id, params) {
    const user = JSON.parse(localStorage.getItem('user'));
    params.updated_by = user.u;
    return this.http.put(`${environment.apiUrl}/studies/${id}`, params)
      .pipe(map(x => {
        // update stored user if the logged in user updated their own record

        return x;
      }));
  }

  delete(id: string) {
    return this.http.delete<Questions>(`${environment.apiUrl}/studies/${id}`);
  }

  deleteFrom(id: string) {
    return this.http.delete(`${environment.apiUrl}/StudyForms/${id}`);
  }

  close(id: string) {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http.get(`${environment.apiUrl}/studyclose/${id}/${user.u}`);
  }

  production(id: string) {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http.get(`${environment.apiUrl}/studyproduction/${id}/${user.u}`);
  }

  archive(id: string) {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http.get(`${environment.apiUrl}/studyArchive/${id}/${user.u}`);
  }

  getSite(id: string) {
    return this.http.get(`${environment.apiUrl}/getsitebyorg/${id}`);
  }
  getUserBySite(orgId,site) {
    const data = {'orgId': orgId, 'site' : site};
    return this.http.post(`${environment.apiUrl}/getusersbysite`, data);
  }

  copyStudy(data: Studies) {
    const user = JSON.parse(localStorage.getItem('user'));
    data.created_by = user.u;
    return this.http.post(`${environment.apiUrl}/copyStudy`, data);
  }

  /// Form

  createForms(data: Studies) {
    const user = JSON.parse(localStorage.getItem('user'));
    data.created_by = user.u;
    return this.http.post(`${environment.apiUrl}/StudyForms`, data);
  }

  updateForms(id, params) {
    const user = JSON.parse(localStorage.getItem('user'));
    params.updated_by = user.u;
    return this.http.put(`${environment.apiUrl}/StudyForms/${id}`, params)
      .pipe(map(x => {
        // update stored user if the logged in user updated their own record

        return x;
      }));
  }

  editstudyForm(id: string) {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http.get(`${environment.apiUrl}/StudyForms/${id}`);
  }


}
