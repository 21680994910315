// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   defaultauth: 'fackbackend',
//   firebaseConfig: {
//     apiKey: '',
//     authDomain: '',
//     databaseURL: '',
//     projectId: '',
//     storageBucket: '',
//     messagingSenderId: '',
//     appId: '',
//     measurementId: ''
//   }
// };
// export const baseUrl='http://43.205.77.232/soft';
export const environment = {
  production: false,
  // apiUrl: 'http://220.158.165.135/soft' // new metro server
   apiUrl: 'https://app.sempercentrum.com/soft'
    // apiUrl: 'http://103.225.42.226/soft'
  // apiUrl: 'http://202.21.38.32/sempercentrum/soft' // elevk server
  // apiUrl: 'https://www.swsdev.online/sempercentrum/soft',
  // apiUrl: 'http://localhost/sempercentrum/soft',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
