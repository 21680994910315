import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import {Dashboard} from './dashboard';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {


  private studiesSubject: BehaviorSubject<Dashboard>;
  private studies: Observable<Dashboard>;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.studiesSubject = new BehaviorSubject<Dashboard>(JSON.parse(localStorage.getItem('user')));
    this.studies = this.studiesSubject.asObservable();
  }


  get(data) {
    const user = JSON.parse(localStorage.getItem('user'));
    data.uid = user.u;
    return this.http.post(`${environment.apiUrl}/dashboard`, data);
    // return this.http.get<Dashboard[]>(`${environment.apiUrl}/dashboard`);
  }


}
