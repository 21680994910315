import {Component, OnInit, QueryList, ElementRef, ViewChild, ViewChildren } from '@angular/core';
import { GridsterConfig, GridsterItem } from 'angular-gridster2';
import {first} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ResponseService} from '../../response/response.service';
import {ChartType} from '../../analysis/graph/chartjs.model';
import {
  lineAreaChart,
  lineBarChart,
  pieChart,
  donutChart,
  radarChart,
  polarChart,
  lineColumAreaChart,
  bubbleChart
} from './data';
@Component({
  selector: 'app-render',
  templateUrl: './render.component.html',
  styleUrls: ['./render.component.css'],
})
export class RenderComponent {
  @ViewChildren('pythonOutputFrame') pythonOutputFrames: QueryList<ElementRef>;
  id: number;
  public options: GridsterConfig;
  public items: GridsterItem[];
  chartArray: any;
  chartFieldArray: any;
  questionArray: any;
  typeArray: any;
  formArray: any;
  msg: any;
  type: boolean = false;
  data: any;
  form: FormGroup;
  loading = false;
  submitted = false;
  isAddMode: boolean;
  public sid: any;
  public siteId: any;
  lineChart: boolean = false;
  barChart: boolean = false;
  _pieChart: boolean = false;
  trendChart: boolean = false;
  scatterChart: boolean = false;
  // Line Chart
  lineColumAreaChart: ChartType;
  lineAreaChart: ChartType;
  // Bar Chart
  lineBarChart: ChartType;
  // Pie Chart
  pieChart: ChartType;
  bubbleChart: ChartType;
  sites: any;
  studyName: any;
  sitesArray:any=[];
  loader: boolean =false;
  constructor(public responseService: ResponseService,
              private route: ActivatedRoute) {
    this.isAddMode = !this.id;
    this.id = this.route.snapshot.params.id;
    localStorage.setItem('render_grid_id', JSON.stringify(this.id));
    this.studyName = JSON.parse(localStorage.getItem('render_study_name'));
    this.chartArray = [
      {name: 'Line'},
      {name: 'Bar'},
      {name: 'Pie'},
      {name: 'Trend'},
      {name: 'Scatter'},
    ];

    // this.items = [{x: 0, y: 0, rows: 2, cols: 6, isChecked: false, isGroup: false}];

    //  { x: 0, y: 5, rows: 3, cols: 11, isChecked: false, isGroup: false },
    this.options = {
      pushItems: true,
      minCols: 12,
      maxCols: 12,
      minRows: 5,
      fixedRowHeight: 120,
      setGridSize: true,
      mobileBreakpoint: 0,
      gridType: 'fit',
      resizable: {
        enabled: false,
      },
      draggable: {
        enabled: false,
      },
    };
    // this.checkForEmptySpaces();
    // this.getMinItem();

    this._fetchData();

  }

  private _fetchData() {
    // this.bubbleChart = bubbleChart;
    // Line Chart data
    this.lineColumAreaChart = lineColumAreaChart;
    this.lineAreaChart = lineAreaChart;
    // Bar Chart data
    this.lineBarChart = lineBarChart;
    // Pie Chart data
    this.pieChart = pieChart;
    this.sid = JSON.parse(localStorage.getItem('render_study_id'));
    this.sites = [{id: 0, site_name: 'All Sites'}];
    this.responseService.getSitebyStudy(this.sid)
      .subscribe(sites => {
        this.sitesArray = sites;
        // tslint:disable-next-line:no-shadowed-variable
        this.sitesArray.forEach(e => {
          this.sites.push(e);
        });
      });
  }

  siteChange(siteId) {
    localStorage.setItem('render_site_id', JSON.stringify(siteId));
    this.getData();
    this.loader=true;
  }
  getData() {
    this.loading = true;
    this.sid = JSON.parse(localStorage.getItem('render_study_id'));
    this.siteId = JSON.parse(localStorage.getItem('render_site_id'));

    this.responseService.getDashboardData(this.sid, this.siteId, this.id)
      .subscribe(data => {
        // @ts-ignore
        if (data.status === 1) {
          this.loading = false;
          this.type = true;
          // @ts-ignore
          this.items = data.data.items;
          // @ts-ignore
          this.chartFieldArray = data.data.chartFieldArray;
          // @ts-ignore
          this.questionArray = data.data.questionArray;
          // @ts-ignore
          this.typeArray = data.data.typeArray;
          // @ts-ignore
          this.formArray = data.data.formArray;
          setTimeout(() => {
            this.typeArray.forEach((data,i)=>{
              if(this.typeArray[i] == 'Iframe') {
                this.showOutput(this.questionArray[i], i);
              }
            });
          }, 2000);
          this.loader=false;

        } else {
          // @ts-ignore
          this.msg = data.msg;
        }
        // console.log(this.newData)

      });

  }
  showOutput(data, index) {
    const iframeElement = this.pythonOutputFrames.toArray()[index].nativeElement;
    iframeElement.contentDocument.open();
    iframeElement.contentDocument.write(data);
    iframeElement.contentDocument.close();
  }




}

