import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import {latLng, tileLayer} from 'leaflet';

import {ChartType, Stat, Chat, Transaction} from './dashboard.model';

import {DashboardService} from './dashboard.service';


import {
  linewithDataChart,
  statData,
  revenueChart,
  chemoChart,
  salesAnalytics,
  sparklineEarning,
  sparklineMonthly,
  chatData,
  transactions,
  pieChart
} from './data';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

/**
 * Dashboard Component
 */
export class DashboardComponent implements OnInit {

  term: any;
  chatData: Chat[];
  transactions: Transaction[];
  statData: Stat[];
  pieChart: ChartType;
  studies: any;
  alerts: any;

  constructor(
    public formBuilder: FormBuilder,
    public dashboardService: DashboardService
  ) {
  }

  // bread crumb items
  breadCrumbItems: Array<{}>;
  linewithDataChart: ChartType;
  revenueChart: ChartType;
  chemoChart: ChartType;
  salesAnalytics: ChartType;
  sparklineEarning: ChartType;
  sparklineMonthly: ChartType;
  data: any = {};

  // Form submit
  chatSubmit: boolean;

  formData: FormGroup;


  options = {
    layers: [
      tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {maxZoom: 18, attribution: '...'})
    ],
    zoom: 6,
    center: latLng(46.879966, -121.726909)
  };

  ngOnInit(): void {
    this.breadCrumbItems = [{label: 'Semper Centrum'}, {label: 'Dashboard', active: true}];
    this.formData = this.formBuilder.group({
      message: ['', [Validators.required]],
    });
    this._fetchData();
  }

  private _fetchData() {
    this.revenueChart = revenueChart;
    this.salesAnalytics = salesAnalytics;
    this.sparklineEarning = sparklineEarning;
    this.sparklineMonthly = sparklineMonthly;
    this.chatData = chatData;
    this.transactions = transactions;
    this.statData = statData;
    this.pieChart = pieChart;
    this.chemoChart = chemoChart;
    this.linewithDataChart = linewithDataChart;
    this.filter();
  }

  filter(data = null) {
    this.dashboardService.get(this.data)
      .subscribe(x => {
          // console.log(x);
          // @ts-ignore
          this.statData = x.statData;
          // @ts-ignore
          this.studies = x.studies;
        // @ts-ignore
          this.alerts = x.alerts;
        }
      );
  }

  studiesFilter(studyName) {
    // @ts-ignore

    this.data.studyName = studyName;
    this.dashboardService.get(this.data)
      .subscribe(x => {
          // @ts-ignore
          this.statData = x.statData;
          // @ts-ignore
          this.studies = x.studies;
        }
      );
  }

  /**
   * Returns form
   */
  get form() {
    return this.formData.controls;
  }

  /**
   * Save the message in chat
   */
  messageSave() {
    const message = this.formData.get('message').value;
    const currentDate = new Date();
    if (this.formData.valid && message) {
      // Message Push in Chat
      this.chatData.push({
        align: 'right',
        name: 'Ricky Clark',
        message,
        time: currentDate.getHours() + ':' + currentDate.getMinutes()
      });

      // Set Form Data Reset
      this.formData = this.formBuilder.group({
        message: null
      });
    }

    this.chatSubmit = true;
  }

  chartFilter(sid: number, filter: string) {
    this.data.filter = filter;
    this.data.sid = sid;
    this.dashboardService.get(this.data)
      .pipe(first())
      .subscribe(x => {
          // console.log(x);
          // @ts-ignore
          this.statData = x.statData;
          // @ts-ignore
          this.studies = x.studies;
        }
      );
  }

}
