import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-code-editor',
  templateUrl: './code-editor.component.html',
  styleUrls: ['./code-editor.component.scss']
})
export class CodeEditorComponent {
  pythonCode: string = '';
  @ViewChild('pythonOutputFrame', { static: false }) pythonOutputFrame!: ElementRef;

  runPythonCode() {
    const pythonCode = this.pythonCode;

    // You should perform input validation and sanitation before executing the code.
    // For simplicity, we're not handling security aspects in this example.

    // Prepare the Python code for execution (e.g., wrapping it in a function).
    const wrappedCode = `
      <html>
      <body>
        <pre>
          <code>
            ${pythonCode}
          </code>
        </pre>
      </body>
      </html>
    `;

    // Update the iframe content to display the Python code.
    this.pythonOutputFrame.nativeElement.contentDocument.open();
    this.pythonOutputFrame.nativeElement.contentDocument.write(wrappedCode);
    this.pythonOutputFrame.nativeElement.contentDocument.close();
  }
}
