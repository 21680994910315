import {ChartType} from './dashboard.model';

const revenueChart: ChartType = {
  series: [{
    name: '',
    type: 'column',
    data: [70, 90, 70, 70, 80, 60]
  }],
  chart: {
    height: 450,
    type: 'line',
    toolbar: {
      show: false,
    }
  },
  stroke: {
    width: [0, 3],
    curve: 'smooth'
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '40%',
    },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  colors: ['#5664d2', '#1cbb8c'],
  labels: ['Jan', 'Feb', '', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
};

const chemoChart: ChartType = {
  series: [{
    name: '',
    type: 'column',
    data: [15, 0, 25, 0, 10, 0, 0, 0]
  }],
  chart: {
    height: 350,
    type: 'line',
    toolbar: {
      show: false,
    }
  },
  stroke: {
    width: [0, 3],
    curve: 'smooth'
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '40%',
    },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  colors: ['#0362AB', '#000'],
  labels: ['AC-T', 'ACTH', 'CMF', 'ACTHP', 'TC', 'TCH', 'TCHP', 'TH'],
};

const salesAnalytics: ChartType = {
  series: [5, 15, 35, 45],
  chart: {
    height: 230,
    type: 'donut',
  },
  labels: ['Stage I', 'Stage II', 'Stage III', 'Stage IV'],
  plotOptions: {
    pie: {
      donut: {
        size: '75%'
      }
    }
  },
  dataLabels: {
    enabled: false
  },
  legend: {
    show: false,
  },
  colors: ['#5664d2', '#1cbb8c', '#eeb902', '#e65510'],
};

const sparklineEarning: ChartType = {
  series: [72],
  chart: {
    type: 'radialBar',
    wight: 60,
    height: 60,
    sparkline: {
      enabled: true
    }
  },
  dataLabels: {
    enabled: false
  },
  colors: ['#5664d2'],
  stroke: {
    lineCap: 'round'
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: '70%'
      },
      track: {
        margin: 0,
      },

      dataLabels: {
        show: false
      }
    }
  }
};

const sparklineMonthly: ChartType = {
  series: [65],
  chart: {
    type: 'radialBar',
    wight: 60,
    height: 60,
    sparkline: {
      enabled: true
    }
  },
  dataLabels: {
    enabled: false
  },
  colors: ['#1cbb8c'],
  stroke: {
    lineCap: 'round'
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: '70%'
      },
      track: {
        margin: 0,
      },

      dataLabels: {
        show: false
      }
    }
  }
};

const chatData = [
  {
    id: 1,
    name: 'Frank Vickery',
    message: 'Hey! I am available',
    image: 'assets/images/users/avatar-2.jpg',
    time: '12:09'
  },
  {
    id: 2,
    align: 'right',
    name: 'Ricky Clark',
    message: 'Hi, How are you? What about our next meeting?',
    time: '10:02'
  },
  {
    text: 'Today'
  },
  {
    id: 3,
    name: 'Frank Vickery',
    message: 'Hello!',
    image: 'assets/images/users/avatar-2.jpg',
    time: '10:06'
  },
  {
    id: 4,
    name: 'Frank Vickery',
    message: '& Next meeting tomorrow 10.00AM',
    image: 'assets/images/users/avatar-2.jpg',
    time: '10:06'
  },
  {
    id: 5,
    align: 'right',
    name: 'Ricky Clark',
    message: 'Wow that\'s great',
    time: '10:07'
  }
];

const transactions = [
  {
    orderid: '#NZ1563',
    date: '28 Mar, 2020',
    billingname: 'Frank Dean',
    total: '$164',
    paymentstatus: 'Unpaid'
  },
  {
    orderid: '#NZ1564',
    date: '28 Mar, 2020',
    billingname: 'Eddy Torres',
    total: '$141',
    paymentstatus: 'Paid'
  },
  {
    orderid: '#NZ1565',
    date: '29 Mar, 2020',
    billingname: 'Jamison Clark',
    total: '$123',
    paymentstatus: 'Paid'
  },
  {
    orderid: '#NZ1566',
    date: '30 Mar, 2020',
    billingname: 'Jewel Buckley',
    total: '$112',
    paymentstatus: 'Paid'
  },
  {
    orderid: '#NZ1567',
    date: '31 Mar, 2020',
    billingname: 'Jeffrey Waltz',
    total: '$105',
    paymentstatus: 'Unpaid'
  },
  {
    orderid: '#NZ1568',
    date: '01 Apr, 2020',
    billingname: 'Jefferson Allen',
    total: '$160',
    paymentstatus: 'Chargeback'
  }
];

const statData =
  [
    {
      icon: 'ri-stack-line',
      title: 'Number of Studies',
      value: '10'
    }
  ];
const pieChart: ChartType = {
  labels: [
    'AC-T', 'ACTH', 'CMF', 'ACTHP', 'TC', 'TCH', 'TCHP', 'TH'
  ],
  datasets: [
    {
      data: [15, 0, 25, 0, 10, 0, 0, 0],
      backgroundColor: ['#1cbb8c', '#ebeff2', '#ebefd1', '#ebef1d', '#ebe002', '#e9cff2', '#eccff2', '#1beff2'],
      hoverBackgroundColor: ['#1cbb8c', '#ebeff2', '#ebefd1', '#ebef1d', '#ebe002', '#e9cff2', '#eccff2', '#1beff2'],
      hoverBorderColor: '#fff',
    }
  ],
  options: {
    maintainAspectRatio: false,
    legend: {
      position: 'top',
    }
  }
};

// @ts-ignore
const linewithDataChart: ChartType =
  {
  chart: {
    height: 380,
    type: 'line',
    zoom: {enabled: false},
    toolbar: {show: false}
  },
  colors: ['#556ee6', '#34c38f'],
  dataLabels: {enabled: false},
  stroke: {width: [3, 3], curve: 'straight'},
  series: [{name: 'Patients', data: [0, 0, 0, 0, 0, 2, 12, 22, 0, 0, 0, 0]}],
  title: {text: '', align: 'left'},
  grid: {row: {colors: ['transparent', 'transparent'], opacity: '0.2'}, borderColor: '#f1f1f1'},
  markers: {style: 'inverted', size: '6'},
  xaxis: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    title: {text: 'Month'}
  },
  yaxis: {min: 5, max: 40, title: {text: 'No. of Patients'}},
  legend: {position: 'top', horizontalAlign: 'right', floating: true, offsetY: '-25', offsetX: '-5'},
  responsive: [{breakpoint: '600', options: {chart: {toolbar: {show: false}}, legend: {show: false}}}]
};
export {
  linewithDataChart,
  revenueChart,
  chemoChart,
  salesAnalytics,
  sparklineEarning,
  sparklineMonthly,
  chatData,
  transactions,
  statData,
  pieChart
};
