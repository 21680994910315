import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RenderComponent } from './dashboard_render/render.component';
import { ViewComponent } from './dashboard_render/view/view.component';


import {AuthGuard} from '../_helpers';

const routes: Routes = [
    { path: '', component: DashboardComponent },
    { path: 'dashboard-grid-list/:id', component: ViewComponent },
    { path: 'dashboard-render/:id', component: RenderComponent },
    { path: 'pages', loadChildren: () => import('./utility/utility.module').then(m => m.UtilityModule) },
    { path: 'ui', loadChildren: () => import('./ui/ui.module').then(m => m.UIModule) },
    { path: 'form', loadChildren: () => import('./form/form.module').then(m => m.FormModule) },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule { }
