import {Component, OnInit, Inject, Output, EventEmitter} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';

import {AuthenticationService} from '../../../core/services/auth.service';
import {AuthfakeauthenticationService} from '../../../core/services/authfake.service';
import {LanguageService} from '../../../core/services/language.service';
import {environment} from '../../../../environments/environment';
import {AccountService} from '../../../_services/account.service';
import {DashboardService} from '../../../pages/dashboard/dashboard.service';
import {User} from '../../../_models';
import {first} from "rxjs/operators";
import { DashboardComponent } from '../../../pages/dashboard/dashboard.component';
@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  element: any;
  configData: any;
  cookieValue;
  flagvalue;
  countryName;
  valueset: string;
  logs:boolean=false;
  listLang = [
    {text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en'},
    {text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es'},
    {text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de'},
    {text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it'},
    {text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru'},
  ];

  // tslint:disable-next-line: max-line-length
  user: User;
  username: any = 'User';
  data: any = {};
  studies: any;
  is_angiowave : any = '';
  constructor(@Inject(DOCUMENT) private document: any, private router: Router, private authService: AuthenticationService, private authFackservice: AuthfakeauthenticationService, public languageService: LanguageService, public cookiesService: CookieService, private accountService: AccountService, private dashboardService: DashboardService) {
    this.accountService.user.subscribe(x => this.user = x);
    const user = JSON.parse(localStorage.getItem('user'));
    this.username = user.username;
    this.is_angiowave = JSON.parse(localStorage.getItem('angiowave'));
  }

  @Output() mobileMenuButtonClicked = new EventEmitter();
  @Output() settingsButtonClicked = new EventEmitter();

  ngOnInit(): void {

    const user = JSON.parse(localStorage.getItem('user'));
    if(user.role_id==1){
      this.logs=true;
    }
    this.element = document.documentElement;
    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3
    };

    this.cookieValue = this.cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = 'assets/images/flags/us.jpg';
      }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  /**
   * Translate language
   */
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }


  /**
   * Logout the user
   */
  logout() {
    this.accountService.logout();
  }

  studiesFilter(studyName) {
    // @ts-ignore
    const myCompOneObj = new DashboardComponent();

    this.data.studyName = studyName;
    this.dashboardService.get(this.data)
      .subscribe(x => {
          // @ts-ignore
        myCompOneObj.retrunFunction(x);
        }
      );
  }
}
