import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';

import {HttpClient} from '@angular/common/http';
import {Sidebar} from "./sidebar";

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
  }

  getSidebarData(role){
    const user = JSON.parse(localStorage.getItem('user'));
    return this.http.get<Sidebar>(`${environment.apiUrl}/getsidebardata/${role}/${user.u}`);
  }
}

