import {Component, OnInit, SimpleChanges, ViewChild, ChangeDetectionStrategy} from '@angular/core';
import {GridsterConfig, GridsterItem} from 'angular-gridster2';
import {first} from 'rxjs/operators';
import {QuestionsService} from './questions/questions.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ResponseService} from '../response/response.service';
import {IframeService} from './iframe/iframe.service';
import {StudyGridService} from './studyGrid.service';
import {ValidatorFn, AbstractControl} from '@angular/forms';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css'],

})
export class GridComponent implements OnInit {
  chartArray: any;
  questionType: any = [];
  isChartField: any = [];
  isTableField: any = [];
  isiFrameField: any = [];

  iframeQuestions: any;

  id: number;
  sid: number;
  public options: GridsterConfig;
  items: GridsterItem[] = [];
  data: any;
  form: FormGroup;
  loading = false;
  submitted = false;
  isAddMode: boolean;
  chartFieldArray: any = [];
  questionArray: any = [];
  urlArray: any = [];
  typeArray: any = [];

  myPanes: Array<Pane> = new Array();
  itemsToBeChecked: Array<GridsterItem> = new Array();
  finalPane;
  selectedChartValues: any = [];
  selectedTypeValues: any = [];
  selectedQuestionValues: any = [];
  selectedIframeQuestionValues: any = [];
  breadCrumbItems: Array<{}>;
  dataArray: any = [];
  formArray: FormArray;
  andVal: any = '';
  conditionArray: any = [
    {name: 'and'},
    {name: 'or'},
  ];

  constructor(public responseService: ResponseService,
              private questionsService: QuestionsService,
              private studyGridService: StudyGridService,
              private iframeService: IframeService,
              private route: ActivatedRoute,
              private router: Router,
              public formBuilder: FormBuilder) {

    this.sid = JSON.parse(localStorage.getItem('builder_study_id'));

    this.questionsService.getAllQuestionsByStudy(this.sid)
      .pipe(first())
      .subscribe(data => {
        this.data = data;
      });

    this.chartArray = [
      {name: 'Line'},
      {name: 'Bar'},
      {name: 'Pie'},
      {name: 'Trend'},
      {name: 'Scatter'},
    ];

    this.questionType = [
      {name: ''},
      {name: 'Table'},
      {name: 'Chart'},
      {name: 'Iframe'}
    ];
    this.options = {
      pushItems: true,
      minCols: 12,
      maxCols: 12,
      minRows: 5,
      fixedRowHeight: 120,
      setGridSize: true,
      mobileBreakpoint: 0,
      gridType: 'fit',
      resizable: {
        enabled: true,
      },
      draggable: {
        enabled: true,
      },
    };
  }


  ngOnInit() {
    this.breadCrumbItems = [{label: 'Studies'}, {label: 'Add Query Question', active: true}];
    this.id = this.route.snapshot.params.id;
    this.isAddMode = !this.id;
    this.sid = JSON.parse(localStorage.getItem('builder_study_id'));
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      is_bookmark: [''],
      formArray: new FormArray([]),
      typeField: new FormControl(''),
      chartField: new FormControl(''),
      questions: new FormControl(''),
      iframe: new FormControl(''),
    });
    this.iframeService.getByStudyId(this.sid)
      .subscribe(data => {
        // @ts-ignore
        this.iframeQuestions = data;

      });
    if (!this.isAddMode) {
      this.getEditData();
    } else {
      this.addGrid();
    }
    // @ts-ignore
    this.dataArray = this.form.get('formArray').controls;
  }

  getEditData() {
    this.studyGridService.getById(this.id)
      .subscribe(data => {
        // @ts-ignore
        data.items.forEach((item) => {
          this.addGrid(item);
        });

        // @ts-ignore
        data.formArray.forEach((res, i) => {
          this.typeChange(res.typeField, i);

        });

        this.form.patchValue(data);
      });
  }

  addGrid(newItem = null) {
    if (newItem == null) {
      const newItem1 : GridsterItem = {
        x: 0,
        y: 0,
        rows: 2,
        cols: 6,
        isChecked: false,
        isGroup: false,
      };
      this.items.push(newItem1);
    }else {
      this.items.push(newItem);
    }
    this.formArray = this.form.get('formArray') as FormArray;
    this.formArray.push(
      new FormGroup({
        typeField: new FormControl(this.form.get('typeField').value),
        chartField: new FormControl(this.form.get('chartField').value),
        questions: new FormControl(this.form.get('questions').value),
        iframe: new FormControl(this.form.get('iframe').value)
      })
    );
  }

  removeGrid(i) {
    this.items.splice(i, 1);
    let formArray = this.form.get('formArray') as FormArray;
    formArray.removeAt(i);
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }


  typeChange(event, i) {
    this.isChartField[i] = false;
    this.isTableField[i] = false;
    this.isiFrameField[i] = false;
    if (event == 'Chart') {
      this.isChartField[i] = true;
    } else if (event == 'Table') {
      this.isTableField[i] = true;
    } else if (event == 'Iframe') {
      this.isiFrameField[i] = true;
    }
  }



  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.createUser();
    } else {
      this.updateUser();
    }
  }

  createUser() {
    this.form.value.study_id = this.sid;
    this.form.value.items = this.items;
    const data = this.form.value;

    this.studyGridService.create(data)
      .subscribe({
        next: () => {
          alert('Dashboard added successfully');
          this.reloadComponent();
        },
        error: error => {
          alert('Error While Processing');
          this.loading = false;
        }
      });
  }

  private updateUser() {
    this.form.value.study_id = this.sid;
    this.form.value.items = this.items;
    const data = this.form.value;
    this.studyGridService.update(this.id, data)
      .subscribe({
        next: () => {
          alert('Dashboard Updated successfully');
          this.router.navigateByUrl('/studies/dashboard-builders/' + this.sid);
        },
        error: error => {
          alert('Error While Processing');
          this.loading = false;
        }
      });
  }

  reloadComponent() {
    const currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  public convetGridsterItems() {
    let itemsToCheck = this.itemsToBeChecked.filter((item) => !item.isGroup);
    if (itemsToCheck.length == 0) {
      this.itemsToBeChecked.forEach((i) => {
        i.isGroup = false;
      });
      itemsToCheck = this.itemsToBeChecked;
    }
    const firstContainer = this.getMinItem(itemsToCheck);
    const paneA = new Pane(
      firstContainer.x,
      firstContainer.y,
      firstContainer.rows,
      firstContainer.cols
    );
    paneA.panes = firstContainer.panes;
    this.itemsToBeChecked = this.itemsToBeChecked.filter(
      (item) => !item.isChecked
    );

    const horizontalContainers = this.getContainer(
      firstContainer,
      'horizontal'
    );
    if (horizontalContainers.length > 0) {
      paneA.orientation = firstContainer.orientation;
      const groupPaneHorizontal = this.createGroupPanel(
        [paneA, ...horizontalContainers],
        'horizontal'
      );

      this.addItemToToBeCheckedArray(groupPaneHorizontal);
    } else {
      const verticalContainers = this.getContainer(firstContainer, 'vertical');
      if (verticalContainers.length > 0) {
        paneA.orientation = firstContainer.orientation;
        const groupPaneVertical = this.createGroupPanel(
          [paneA, ...verticalContainers],
          'vertical'
        );
        this.addItemToToBeCheckedArray(groupPaneVertical);
      } else {
        firstContainer.isGroup = true;
      }
    }
  }

  private getContainer(
    primaryContainer,
    orientation: 'horizontal' | 'vertical'
  ) {
    const panes: Array<Pane> = new Array();
    let container;
    if (orientation == 'horizontal') {
      container = this.itemsToBeChecked.find(
        (item) =>
          item.y == primaryContainer.y &&
          item.rows == primaryContainer.rows &&
          (item.x == primaryContainer.x + primaryContainer.cols ||
            primaryContainer.x == item.x + item.cols)
      );
    } else {
      /*container = this.itemsToBeChecked.find(
        (item) =>
          item.x == primaryContainer.x &&
          item.y == primaryContainer.y + primaryContainer.rows &&
          item.cols == primaryContainer.cols
      );*/

      container = this.itemsToBeChecked.find(
        (item) =>
          item.x == primaryContainer.x &&
          primaryContainer.cols == item.cols &&
          (primaryContainer.y == item.y + item.rows ||
            item.y == primaryContainer.y + primaryContainer.rows)
      );
    }

    if (container) {
      container.isChecked = true;
      primaryContainer.isChecked = true;

      container.isGroup = true;
      primaryContainer.isGroup = true;

      const paneB = new Pane(
        container.x,
        container.y,
        container.rows,
        container.cols
      );
      paneB.panes = container.panes;
      paneB.orientation = container.orientation;
      // TO BE CHECKED MONDAY
      this.itemsToBeChecked = this.itemsToBeChecked.filter(
        (item) => !item.isChecked
      );
      const otherContainers = this.getContainer(paneB, orientation);
      if (otherContainers.length > 0) {
        panes.push(...otherContainers);
      }
      panes.push(paneB);
      return panes;
    }
    return [];
  }


  private checkForEmptySpaces() {
    const maxCols = this.findMaxCols();
    const maxRows = this.findMaxRows();
    this.items.forEach((item) => {
      // horizontalCheck to left

      if (item.x != 0) {
        let minLeftX = item.x;
        this.items.forEach((i) => {
          if (
            i.x < item.x &&
            ((i.y <= item.y && i.y + i.rows > item.y) ||
              (i.y < item.y + item.rows && i.y + i.rows > item.y))
          ) {
            // debugger;
            const tempX = item.x - (i.x + i.cols);
            if (tempX < minLeftX) {
              minLeftX = tempX;
            }
          }
        });
        // console.log(item, minLeftX);
        item.x = item.x - minLeftX;
        item.cols = item.cols + minLeftX;
      }

      // horizontalCheck to right
      if (item.x + item.cols < maxCols) {
        let minRightX = maxCols - (item.x + item.cols);
        this.items.forEach((i) => {
          // debugger;
          if (
            i.x > item.x &&
            ((i.y <= item.y && i.y + i.rows > item.y) ||
              (i.y < item.y + item.rows && i.y + i.rows > item.y))
          ) {
            // debugger;
            const tempX = i.x - (item.x + item.cols);
            if (tempX < minRightX) {
              minRightX = tempX;
            }
          }
        });
        console.log(item, minRightX);
        item.cols = item.cols + minRightX;
      }
      // vertical check to top
      if (item.y != 0) {
        let minTopY = item.y;
        this.items.forEach((i) => {
          if (
            i.y < item.y &&
            ((i.x <= item.x && i.x + i.cols > item.x) ||
              (i.x < item.x + item.cols && i.x + i.cols > item.x))
          ) {
            // debugger;
            const tempY = item.y - (i.y + i.rows);
            if (tempY < minTopY) {
              minTopY = tempY;
            }
          }
        });
        // console.log(item, minTopY);
        item.y = item.y - minTopY;
        item.rows = item.rows + minTopY;
      }
      // vertical check to bottom
      if (item.y + item.rows < maxRows) {
        let minBottomY = maxRows - (item.y + item.rows);
        this.items.forEach((i) => {
          if (
            i.y > item.y &&
            ((i.x <= item.x && i.x + i.cols > item.x) ||
              (i.x < item.x + item.cols && i.x + i.cols > item.x))
          ) {
            const tempY = i.y - (item.y + item.rows);
            if (tempY < minBottomY) {
              minBottomY = tempY;
            }
          }
        });
        // console.log(item, minBottomY);
        item.rows = item.rows + minBottomY;
      }
    });
  }

  private findMaxCols() {
    let maxCols = 0;
    this.items.forEach((item) => {
      if (item.x + item.cols > maxCols) {
        maxCols = item.x + item.cols;
      }
    });

    return maxCols;
  }

  private findMaxRows() {
    let maxRows = 0;
    this.items.forEach((item) => {
      if (item.y + item.rows > maxRows) {
        maxRows = item.y + item.rows;
      }
    });

    return maxRows;
  }

  private createGroupPanel(containers: Array<any>, orientation) {
    const minX = this.getMinX(containers);
    const minY = this.getMinY(containers);
    let rows = 0;
    let cols = 0;
    if (orientation == 'horizontal') {
      rows = containers[0].rows;
      // cols = containers[0].cols + containers[1].cols;
      containers.forEach((c) => {
        cols = cols + c.cols;
      });
    } else {
      // rows = containers[0].rows + containers[1].rows;
      cols = containers[0].cols;
      containers.forEach((c) => {
        rows = rows + c.rows;
      });
    }

    const groupPaneHorizontal = new Pane(minX, minY, rows, cols);
    groupPaneHorizontal.panes.push(...containers);
    groupPaneHorizontal.orientation = orientation;

    // console.log(groupPaneHorizontal);
    this.myPanes.push(groupPaneHorizontal);

    return groupPaneHorizontal;
  }

  private addItemToToBeCheckedArray(groupPane) {
    this.itemsToBeChecked.push({
      x: groupPane.x,
      y: groupPane.y,
      rows: groupPane.rows,
      cols: groupPane.cols,
      isChecked: false,
      isGroup: true,
      panes: groupPane.panes,
      orientation: groupPane.orientation,
    });
  }

  // return the item with min x and min y
  private getMinItem(itemsToCheck) {
    const min = itemsToCheck.reduce((prev, current) => {
      // console.log(prev, current);
      if (prev.x < current.x) {
        return prev;
      } else {
        if (prev.x == current.x) {
          if (prev.y <= current.y) {
            return prev;
          } else {
            return current;
          }
        } else {
          return current;
        }
      }
    });
    // min.isChecked = true;
    // console.log(this.items);
    return min;
  }

  private getMinX(containers) {
    const minCont = containers.reduce((prev, current) => {
      return prev.x < current.x ? prev : current;
    });
    return minCont.x;
  }

  private getMinY(containers) {
    const minCont = containers.reduce((prev, current) => {
      return prev.y < current.y ? prev : current;
    });
    return minCont.y;
  }

}

class Pane {
  panes: Array<Pane>;
  x: number;
  y: number;
  rows: number;
  cols: number;
  orientation: 'vertical' | 'horizontal';

  constructor(x: number, y: number, rows: number, cols: number) {
    this.panes = new Array();
    this.x = x;
    this.y = y;
    this.rows = rows;
    this.cols = cols;
  }
}
