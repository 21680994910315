<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title"></h4>
          <div class="row">
            <div class="col-md-6">
              <h4 class="card-title">Study Dashboard Builder</h4>
              <br><br>
            </div>
            <div class="col-md-6">
              <a routerLink="/studies/dashboard-builders/{{sid}}" class="btn btn-warning mr-2 ml-2" style="float: right"><i class="fa fa-arrow-left"></i>
                Back</a>

            </div>
          </div>

          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Name <b class="text-danger">*</b></label>
                  <input type="text" formControlName="name" class="form-control"
                         [ngClass]="{ 'is-invalid': submitted && f.name.errors }"/>
                  <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.required">Name is required</div>
                  </div>
                  <div class="valid-feedback">
                    <span>Looks good!</span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="checkbox" formControlName="is_bookmark" id="is_bookmark"
                         [ngClass]="{ 'is-invalid': submitted && f.is_bookmark.errors }" value="true"/> <label for="is_bookmark">&nbsp; Is Bookmark </label>

                  <div *ngIf="submitted && f.is_bookmark.errors" class="invalid-feedback">
                    <div *ngIf="f.is_bookmark.errors.required">Bookmark is required</div>
                  </div>
                  <div class="valid-feedback">
                    <span>Looks good!</span>
                  </div>
                </div>
              </div>
              <br><br><br>
              <div class="col-12" style="float: right" align="right">
                <span style="float: right" class="btn btn-success mt-2 mr-2" (click)="addGrid()"><i class="fa fa-plus"></i> Add New</span>
              </div>
              <div class="clearfix"></div>
              <br><br><br>
              <gridster [options]="options">
              <div class="col-md-12" formArrayName="formArray" *ngFor="let item of dataArray; let i = index">
                <gridster-item [item]="items[i]">
                <ng-container [formGroupName]="i">
                  <div>
                    <button class="btn btn-xs btn-danger float-right" (click)="removeGrid(i)"><i
                      class="fa fa-trash"></i></button>
                    <br>
                    <div class="col-md-12 form-group">
                        <label>Select Iframe Question <b class="text-danger">*</b></label>
                        <ng-select formControlName="typeField" (change)="typeChange($event,i)">
                          <ng-option *ngFor="let opt of questionType"
                                     [value]="opt.name">{{opt.name}}</ng-option>
                        </ng-select>
                        <div class="valid-feedback">
                          <span>Looks good!</span>
                        </div>
                    </div>
                    <div class="form-group col-12" *ngIf="isChartField[i]">
                      <label>Select Chart <b class="text-danger">*</b></label>
                      <ng-select formControlName="chartField">
                        <ng-option *ngFor="let opt of chartArray" [value]="opt.name">{{opt.name}}</ng-option>
                      </ng-select>
                      <div class="valid-feedback">
                        <span>Looks good!</span>
                      </div>
                    </div>
                    <div class="form-group col-12" *ngIf="isChartField[i] || isTableField[i]">
                      <label>Select Question <b class="text-danger">*</b></label>
                      <ng-select formControlName="questions">
                        <ng-option *ngFor="let opt of data" [value]="opt.id">{{opt.name}}</ng-option>
                      </ng-select>
                      <div class="valid-feedback">
                        <span>Looks good!</span>
                      </div>
                    </div>
                    <div class="col-md-12" *ngIf="isiFrameField[i]">
                      <div class="form-group">
                        <label>Select Iframe Question <b class="text-danger">*</b></label>
                        <ng-select formControlName="iframe">
                          <ng-option *ngFor="let opt of iframeQuestions" [value]="opt.id">{{opt.name}}</ng-option>
                        </ng-select>
                        <div class="valid-feedback">
                          <span>Looks good!</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                </gridster-item>
              </div>
              </gridster>

              <br><br>

              <button [disabled]="loading" class="btn btn-primary">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                <b *ngIf="isAddMode">Save</b>
                <b *ngIf="!isAddMode">Update</b>
              </button>

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
