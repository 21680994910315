<style>
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: -webkit-gradient(linear, left top, left bottom, from(#ccc), to(#ccc));
    box-shadow: inset 2px 2px 2px rgba(255, 255, 255, .25), inset -2px -2px 2px rgba(0, 0, 0, .25);
  }

  ::-webkit-scrollbar-track {
    background-color: #fff;
    background: linear-gradient(to right, #fff, #fff 1px, #ccc 1px, #fff);
  }

  .study .card {
    background: #F1F5F7;
    border: 1px #666 solid;
  }
</style>

<div class="container-fluid">
  <app-pagetitle title="Dashboard" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="row">

    <div class="col-xl-12">
      <ul class="list-unstyled mb-0 pr-3">
        <li *ngFor="let alert of alerts"
            style="border-radius: 5px;padding:10px;border: 1px #ddd solid;background: #faddd7;margin-bottom:5px;width: 100%">
          <div class="conversation-list" style="margin-bottom: -10px;;width: 100%">
            <p>{{alert}}</p>
            <a routerLink="/users/change-password">Change Password Here</a><br>
            <br>
          </div>
        </li>

      </ul>
      <br>
    </div>

    <div class="col-xl-12">
      <div class="row" *ngIf="studies">
        <div *ngFor="let stat of statData; let i = index" [attr.data-index]="i" class="col-md-4">
          <app-stat [title]="stat.title" [value]="stat.value" [icon]="stat.icon" [index]="i"></app-stat>
        </div>
      </div>
      <div class="row" *ngIf="!studies">
        <div class="col" align="center">
          <span class="spinner-border spinner-border-lg align-center"></span>
        </div>
      </div>
    </div>
  </div>

  <!-- end row -->
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">

          <div class="row">
            <div class="col-md-12">
              <form class="app-search d-none d-lg-block">
                <div class="position-relative">
                  <input type="text" class="form-control" (keyup)="studiesFilter($event.target.value)"
                         placeholder="Search by Study name">
                  <span class="ri-search-line"></span>
                </div>
              </form>
              <div class="table table-bordered">
                <table class="table mb-0">

                  <thead>
                  <tr>
                    <th>Study Name</th>
                    <th>Organization</th>
                    <th>Status</th>
                    <th>Start date</th>
                    <th>No. of Sites</th>
                    <th>No. Of Patients</th>

                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let data of studies">
                    <td><a routerLink="dashboard-grid-list/{{data.id}}">{{data.name}}</a></td>
                    <td>{{data.organization}}</td>
                    <td [innerHTML]="data.status"></td>
                    <td>{{data.start_date}}</td>
                    <td>{{data.sites}}</td>
                    <td>{{data.patients}}</td>
                  </tr>
                  <tr *ngIf="!studies">
                    <td colspan="4" class="text-center">
                      <span class="spinner-border spinner-border-lg align-center"></span>
                    </td>
                  </tr>

                  </tbody>
                </table>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

  </div>
  <!-- end row -->


  <!-- end row -->
  <div class="row">
    <div class="col-xl-12">
      <div class="card" *ngFor="let study of studies; let i = index" [attr.data-index]="i">
        <div class="card-body">

          <div class="row">
            <div class="col-md-8 user-chat-border">
              <div class="row" style="border-bottom: 1px #eff2f7 solid">
                <div class="col-md-6" style="margin-bottom: -10px">
                  <h6>
                    <ul style="list-style: none">
                      <li><label>Study Name: </label> {{study.name}}</li>
                      <li><label>Start Date: </label> {{study.start_date}}</li>
                    </ul>
                  </h6>
                </div>
                <div class="col-md-6" style="margin-bottom: -10px">
                  <h6>
                    <ul style="list-style: none">
                      <li><label>Organizations: </label> {{study.organization}}</li>
                      <li><label>No. of Sites: </label> {{study.sites}}</li>
                    </ul>
                  </h6>
                </div>
              </div>
              <h4 class="card-title mb-4"></h4>
              <div class="row study">
                <div class="table table-bordered">
                  <table class="table mb-0">

                    <thead>
                    <tr>
                      <th>Site Name</th>
                      <th>No. of Patients</th>
                      <th>Total Queries</th>
                      <th>Open Queries</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let data of study.eachStudyData">
                      <td>{{data.siteName}}</td>
                      <td>{{data.patients}}</td>
                      <td>{{data.totalQueries}}</td>
                      <td>{{data.openQueries}}</td>
                    </tr>
                    <tr *ngIf="!studies">
                      <td colspan="4" class="text-center">
                        <span class="spinner-border spinner-border-lg align-center"></span>
                      </td>
                    </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="w-100 user-chat mt-4 mt-sm-0" style="border:1px #ddd solid;border-radius: 0.25rem">
                <div class="p-3 px-lg-4 user-chat-border">
                  <div class="row">
                    <div class="col-md-8 col-6">
                      <h5 class="font-size-15 mb-1 text-truncate">Recent Activities</h5>
                    </div>
                    <div class="col-md-4 col-6">
                      <ul class="list-inline user-chat-nav text-right mb-0">


                        <li class="list-inline-item">
                          <!--                            <a href="">View More</a>-->
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="px-lg-2">
                  <div class="chat-conversation p-3" style="padding-right: 0 !important;">
                    <perfect-scrollbar style="height: 250px;">
                      <ul class="list-unstyled mb-0 pr-3">
                        <li *ngFor="let log of study.recentlyActivies; let i = index" [attr.data-index]="i"
                            style="border-radius: 5px;padding:10px;border: 1px #ddd solid;background: antiquewhite;margin-bottom:5px;width: 100%">
                          <div class="conversation-list" style="margin-bottom: -10px;;width: 100%">
                            <span style="float: right">{{log.date}}</span><br>
                            <strong>{{log.title}}</strong>
                            <p>{{log.description}}</p>
                          </div>
                        </li>

                      </ul>
                    </perfect-scrollbar>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br>
          <hr>
          <div class="row">
            <div class="col-md-12">
              <h4 class="card-title mb-4">Patients</h4>
              <div class="table table-bordered">
                <table class="table mb-0">
                  <thead>
                  <tr>
                    <th>Site Name</th>
                    <th>Jan</th>
                    <th>Feb</th>
                    <th>Mar</th>
                    <th>Apr</th>
                    <th>May</th>
                    <th>Jun</th>
                    <th>Jul</th>
                    <th>Aug</th>
                    <th>Sep</th>
                    <th>Oct</th>
                    <th>Nov</th>
                    <th>Dec</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let data of study.eachStudyData">
                    <td>{{data.siteName}}</td>
                    <td>{{data.January}}</td>
                    <td>{{data.February}}</td>
                    <td>{{data.March}}</td>
                    <td>{{data.April}}</td>
                    <td>{{data.May}}</td>
                    <td>{{data.June}}</td>
                    <td>{{data.July}}</td>
                    <td>{{data.August}}</td>
                    <td>{{data.September}}</td>
                    <td>{{data.October}}</td>
                    <td>{{data.November}}</td>
                    <td>{{data.December}}</td>
                  </tr>
                  <tr *ngIf="!studies">
                    <td colspan="4" class="text-center">
                      <span class="spinner-border spinner-border-lg align-center"></span>
                    </td>
                  </tr>

                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- end row -->

</div>
