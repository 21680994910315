import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';

import { environment } from '../environments/environment';
import {FormioAppConfig, FormioModule} from '@formio/angular';

// used to create fake backend
import {FakeBackendInterceptor, fakeBackendProvider} from './_helpers';

import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AppComponent } from './app.component';
import { AlertComponent } from './_components';
import { LayoutsModule } from './layouts/layouts.module';
import { PagesModule } from './pages/pages.module';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {PrismService} from './Prism.service';
import {FormioAuthConfig, FormioAuthService} from '@formio/angular/auth';
import {FormioResources} from '@formio/angular/resource';
import {AppConfig} from './config';
import { GridComponent } from './grid/grid.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MonacoEditorModule } from 'ngx-monaco-editor';

import { GridsterModule } from 'angular-gridster2';
import {NgSelectModule} from '@ng-select/ng-select';
import { CodeEditorComponent } from './code-editor/code-editor.component';

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  imports: [
    FormioModule,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    PagesModule,
    FormsModule,
    LayoutsModule,
    DragDropModule,
    GridsterModule,
    MonacoEditorModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgSelectModule,
    FormsModule,
  ],
  declarations: [
    AppComponent,
    AlertComponent,
    GridComponent,
    CodeEditorComponent,

  ],
  providers: [ PrismService,
    FormioAuthService,
    FormioResources,
    {provide: FormioAppConfig, useValue: AppConfig},
    {provide: FormioAuthConfig, useValue: {
        login: {
          form: 'user/login'
        },
        register: {
          form: 'user/register'
        }
      }},
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true },

    // provider used to create fake backend
    fakeBackendProvider
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }

